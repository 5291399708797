import { isAccessingViaCustomDomain } from '../customDomain';

const getProvider = (space: any, strategy: string) => {
  return space?.included?.authentication_providers?.find(
    (provider: any) => provider.strategy === strategy,
  );
};

export const getFacebookAppId = (space: any) => {
  if (isAccessingViaCustomDomain()) return getProvider(space, 'facebook')?.client_id;
  return window.runtimeEnv?.REACT_APP_FACEBOOK_APP_ID;
};

export const getGoogleClientId = (space: any) => {
  if (isAccessingViaCustomDomain()) return getProvider(space, 'google_oauth2')?.client_id;
  return window.runtimeEnv?.REACT_APP_GOOGLE_CLIENT_ID;
};
const getCsamClientId = (space: any) => space.csam_client_id;

export const hasOAuth = (space: any) =>
  getFacebookAppId(space) || getGoogleClientId(space) || getCsamClientId(space);
