import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';

function PublishingInfo({ publishedAtIso8601, updatedAtIso8601 }: any) {
  return (
    <Helmet>
      {publishedAtIso8601 && (
        <meta property="article:published_time" content={publishedAtIso8601} />
      )}
      {updatedAtIso8601 && <meta property="article:modified_time" content={updatedAtIso8601} />}
    </Helmet>
  );
}

PublishingInfo.defaultProps = {
  publishedAtIso8601: null,
  updatedAtIso8601: null,
};

PublishingInfo.propTypes = {
  publishedAtIso8601: PropTypes.string,
  updatedAtIso8601: PropTypes.string,
};

export default PublishingInfo;
